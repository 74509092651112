import { Button, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { environment } from "../environment";

type Props = {
}

export const AppFooter = ({
}: Props) => {
    const classes = useStyles({});
    return <nav className={classes.header}>
        <Container maxWidth='md'>
            <div className={classes.content}>
                <Button href={`${environment.frontend_url}/privacy`} target='_blank'>Privacy</Button>
                <Button href={`${environment.frontend_url}/terms`} target='_blank'>Terms of service</Button>
                <div className='spacer' style={{ flex: 1 }} />
                <Typography><strong>Certify</strong>Simple &middot; {new Date().getFullYear()}</Typography>
            </div>
        </Container>
    </nav >
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        padding: theme.spacing(6, 0),
        '& button': {
            padding: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '& h6': {
            paddingRight: theme.spacing(4),
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .spacer': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            '& .spacer': {
                display: 'block',
            },
        }
    },
    icon: {
        width: '100%',
        maxWidth: 100,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    }
}))