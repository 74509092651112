import { TextField, MenuItem } from '@material-ui/core';
import React from "react";
import { textWithLineBreaks } from '../../utils/text-with-line-breaks';

export const SelectWidget = (props: any) => {
	const { id, label, onChange, value, placeholder, options, required, rawErrors } = props;
	return (
		<TextField
			id={id}
			value={value || ''}
			placeholder={placeholder}
			onChange={ev => onChange(ev.target.value || undefined)}
			label={textWithLineBreaks(label)}
			required={required}
			error={!!rawErrors && rawErrors.length > 0}
			select
		>{(options?.enumOptions || []).map(({ label, value }: any) => <MenuItem
			key={value}
			value={value}
		>{label}</MenuItem>)}</TextField>
	);
};