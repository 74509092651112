import { Currency } from "./currency";

export type OrganizationModel = {
    id: number;
    name: string;
    description?: string;
    email: string;
    phone?: string;
    username: string;
    logo_image_key?: string;
    currency: Currency;
    stripe_id: string;
}

export const OrganizationFragment = `
    id
    name
    description
    email
    phone
    username
    logo_image_key
    currency
    stripe_id
`;