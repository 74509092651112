import { createStyles, FormHelperText, makeStyles, Theme, useTheme } from '@material-ui/core';
import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';

interface Props {
	card_error?: string;
}

export const StyledCardElement = ({ card_error }: Props) => {
	const theme = useTheme();
	const classes = useStyles({});
	return <React.Fragment>
		<div className={classes.container}>
			<CardElement
				options={{
					// hidePostalCode: true,
					style: {
						base: {
							fontSize: '18px',
							color: theme.palette.text.primary,
							'::placeholder': {
								color: theme.palette.text.secondary,
							}
						},
						invalid: {
							color: theme.palette.secondary.main,
						}
					}
				}} />
		</div>
		{card_error ? <FormHelperText error>{card_error}</FormHelperText> : null}
	</React.Fragment>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
	container: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		border: `1px solid rgba(${theme.palette.type === 'dark' ? '255, 255, 255' : '0, 0, 0'}, 0.20)`,
		'&:hover': {
			border: `1px solid rgba(${theme.palette.type === 'dark' ? '255, 255, 255' : '0, 0, 0'}, 0.87)`,
		}
	},
	testCards: {
		paddingTop: theme.spacing(2),
	},
	cardTable: {
		width: '100%',
	}
}));
