import { Button, createStyles, Link, makeStyles, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab'
import moment from "moment"
import React from "react"
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAppDialog } from "../../app-dialog"
import { environment } from "../../environment"
import { InvoiceModel } from "../../models/invoice.model"
import { PageHeader } from "../../ui/page-header"
import { getFormattedCurrency } from "../../utils/get-formatted-currency"
import { InvoiceItemTickets } from "./invoice-item-tickets"
import { InvoiceRefunds } from "./invoice-refunds"

type Props = {
    invoice: InvoiceModel;
}

export const InvoiceReceipt = ({
    invoice,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();

    const url = `${environment.checkout_url}/invoice/${invoice.id}?secret=${invoice.secret}`

    const onCopy = () => {
        app_dialog.showSnackbar('Link copied. Please save this for future.')
    }
    return <>
        <PageHeader
            no_back
            title={getFormattedCurrency(invoice.amount, invoice.currency)}
            subtitle={`with ${invoice.organization.name}`}
        />
        <Alert action={<CopyToClipboard onCopy={onCopy} text={url}>
            <Button>Copy</Button>
        </CopyToClipboard>}>
            <AlertTitle>Save your receipt link</AlertTitle>
            <Typography variant='body2'>
                {url}
            </Typography>
        </Alert>
        <div className={classes.user}>
            <Typography variant='overline'>Invoice Prepared for</Typography>
            <Typography variant='body1'><strong>{invoice.name}</strong></Typography>
            <Typography variant='body2'>{invoice.email}</Typography>
            <Typography variant='body2' color='textSecondary'>{moment(invoice.status_updated_at || invoice.created_at).calendar()}</Typography>
        </div>
        <div className={classes.items}>
            <Typography variant='overline'>Invoice items</Typography>
            {invoice.invoice_items
                .map(item => <div key={item.id} className={classes.itemWrapper}>
                    <div className={classes.item}>
                        <div>
                            <Link href={`${environment.frontend_url}/e/${item.ticket.event.id}`}>
                                <Typography gutterBottom variant="body1">
                                    {item.ticket.event.title}: <strong>
                                        {item.ticket.name}
                                    </strong>
                                </Typography>
                            </Link>
                            <Typography variant='body1'>
                                {item.quantity} x {getFormattedCurrency(
                                    item.price, invoice.currency)
                                } each</Typography>
                            {item.discount ? <Typography variant='body2'>
                                Discount {getFormattedCurrency(
                                item.discount)}</Typography> : null}
                        </div>
                        <div style={{
                            flex: 1,
                        }} />
                        <Typography variant='h5'><strong>{getFormattedCurrency(
                            item.price * item.quantity, invoice.currency)
                        }</strong></Typography>
                    </div>
                    {item.attendees ? <InvoiceItemTickets attendees={item.attendees} /> : null}
                </div>)}
            <InvoiceRefunds
                currency={invoice.currency}
                invoice_id={invoice.id} />
            <div className={classes.item}>
                <div>
                    <Typography gutterBottom variant="body1">
                        <strong>Subtotal</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5'><strong>{getFormattedCurrency(
                    invoice.subtotal, invoice.currency)
                }</strong></Typography>
            </div>
            {invoice.discount_total ? <div className={classes.item}>
                <div>
                    <Typography gutterBottom variant="body1">
                        <strong>Total Discounts</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5'><strong>-{getFormattedCurrency(
                    invoice.discount_total, invoice.currency)
                }</strong></Typography>
            </div> : null}
            <div className={classes.item}>
                <div>
                    <Typography gutterBottom variant="body1">
                        <strong>Total Paid</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5'><strong>{getFormattedCurrency(
                    invoice.amount, invoice.currency)
                }</strong></Typography>
            </div>
            {(invoice.refund_tally?.total_amount || 0) > 0 ? <div className={classes.item}>
                <div>
                    <Typography color='secondary' gutterBottom variant="body1">
                        <strong>Total Refunded</strong>
                    </Typography>
                </div>
                <div style={{
                    flex: 1,
                }} />
                <Typography variant='h5' color='secondary'><strong>-{getFormattedCurrency(
                    invoice.refund_tally?.total_amount || 0, invoice.currency)
                }</strong></Typography>
            </div> : null}

        </div>
        <div className={classes.footer}>
            <Typography
                variant='body2'
                color='textSecondary'>{invoice.organization.name} partners with CertifySimple & Stripe to provide invoicing and payment processing. Have a question or concern about your invoice? Contact us at help@edropin.com</Typography>
        </div>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    user: {
        padding: theme.spacing(2, 0),
    },
    items: {
        paddingTop: theme.spacing(2),
    },
    itemWrapper: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    item: {
        display: 'flex',
        alignItems: 'center',
    },
    total: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }

    },
    footer: {
        padding: theme.spacing(2, 0),
    },
}))