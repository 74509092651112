import { Typography } from "@material-ui/core";
import React from "react";
import { useAppDialog } from "../app-dialog";
import { InvoiceFragment, InvoiceModel } from "../models/invoice.model";
import { EdContainer } from "../ui/ed-container";
import { LoadingDots } from "../ui/loading-dots";
import { useSubscribeByPk } from "./networking/use-subscribe-by-pk";
import { useUpdateByPk } from "./networking/use-update-by-pk";

export type InvoiceContextModel = {
    invoice_id: number;
    cancel: () => void;
    updating?: boolean;
    invoice: InvoiceModel;
}

export const InvoiceContext = React.createContext<InvoiceContextModel>({} as any);

type Props = {
    invoice_id: number;
}

export const ProvideInvoice: React.FC<Props> = ({ invoice_id, children }) => {
    const app_dialog = useAppDialog();
    const {
        entity,
        error,
        loading,
    } = useSubscribeByPk<InvoiceModel>({
        entity_name: 'invoice',
        fragment: InvoiceFragment,
        pk_columns: [{
            name: 'id',
            value: invoice_id,
            type: 'bigint',
        }]
    });
    const {
        onUpdate,
        loading: updating,
    } = useUpdateByPk({
        entity_name: 'invoice',
        fragment: InvoiceFragment,
        pk_columns: [{
            name: 'id',
            value: invoice_id,
            type: 'bigint',
        }]
    })

    const cancel = () => {
        app_dialog.showDialog({
            title: 'Cancel Invoice',
            message: 'Are you sure you would like to cancel this invoice? This is non-reversible.',
            buttons: [{
                label: 'Dismiss',
            }, {
                label: 'Cancel',
                color: 'secondary',
                onClick: () => onUpdate({
                    cancelled_at: 'now()',
                }),
            }]
        })
    }

    if (loading) {
        return <LoadingDots />
    }

    if (!entity) {
        return <EdContainer>
            <Typography align='center' variant='h6'>Invoice not found.</Typography>
            <Typography align='center'>Please verify your link.</Typography>
        </EdContainer>
    }

    return <InvoiceContext.Provider value={{
        invoice_id,
        cancel,
        updating,
        invoice: entity,
    }}>
        {children}
    </InvoiceContext.Provider>
}

export const useInvoice = (): InvoiceContextModel => {
    return React.useContext(InvoiceContext);
}