import { Button, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useInvoice } from '../apollo/use-invoice';
import { StripePaymentIntentStatus } from '../models/stripe-payment-intent-status.enum';
import { LoadingButton } from '../ui/loading-button';
import { UnstyledLink } from '../ui/unstyled-link';


type Props = {
  onBack?: () => void;
}


export const AppHeader: React.FC<Props> = ({ onBack }) => {
  const classes = useStyles();

  const {
    cancel,
    invoice,
    updating,
  } = useInvoice();

  return (
    <div className={classes.root}>
      <AppBar color='default' position='fixed'>
        <Toolbar>
          <UnstyledLink to={'/'} className={classes.title}>
            <Typography variant='h6'><strong>Certify</strong>Simple</Typography>
          </UnstyledLink>
          <div style={{ flex: 1 }} />
          {invoice.status !== StripePaymentIntentStatus.Succeeded ? <LoadingButton variant='contained'
            loading={updating}
            disabled={!!invoice.cancelled_at}
            onClick={cancel} color='secondary'>Cancel Invoice</LoadingButton> : null}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: 2,
    '& .logo': {
      height: 30,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      },
    },
    '& .icon': {
      height: 30,
      [theme.breakpoints.up('md')]: {
        display: 'none'
      },
    }
  },
  links: {
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1),
      color: 'inherit',
      textDecoration: 'none',
      '&.active': {
        fontWeight: 'bold',
      }
    }
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  toolbar: {
    ...theme.mixins.toolbar,
  }
}));