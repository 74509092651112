import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import amex_svg from './cards/amex.svg';
import diners_svg from './cards/diners.svg';
import discover_svg from './cards/discover.svg';
import jcb_svg from './cards/jcb.svg';
import mastercard_svg from './cards/mastercard.svg';
import unionpay_svg from './cards/unionpay.svg';
import visa_svg from './cards/visa.svg';

type Props = {
	size?: 'small' | 'medium'
}


export const SupportedCardsComponent = ({
	size = 'medium'
}: Props) => {
	const classes = useStyles({});
	const style = {
		width: size === 'medium' ? 50 : 30,
	}
	const cards = [visa_svg, mastercard_svg, amex_svg, discover_svg, diners_svg, jcb_svg, unionpay_svg];
	return <div className={classes.cards}>
		{cards.map(card => <img src={card}
			key={card}
			style={style} />)}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	cards: {
		display: 'flex',
		marginLeft: -theme.spacing(0.5),
		marginRight: -theme.spacing(0.5),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		'& img': {
			margin: theme.spacing(0.5),
			filter: theme.palette.type === 'dark' ? 'none' : 'invert(1)',
		}
	}
}));