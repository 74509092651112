import { useMutation, useQuery } from '@apollo/client';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { useAppDialog } from '../../app-dialog';

export const usePay = () => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<string | undefined>();
	const [pay_success, setPaySuccess] = React.useState(false);
	const app_dialog = useAppDialog();
	const stripe = useStripe();
	const elements = useElements();

	const confirmPayment = async (
		client_secret: string,
	) => {
		if (!stripe || !elements || loading) {
			return;
		}

		const card_element = elements.getElement(CardElement);
		if (!card_element) {
			return;
		}
		setLoading(true);
		try {
			const result = await stripe.confirmCardPayment(
				client_secret, {
				payment_method: {
					card: card_element,
				}
			});
			if (result.error) {
				setError(result.error.message);
				app_dialog.showDialog({
					title: 'Card Error',
					message: result.error.message,
					buttons: [],
				})
				return false;
			}
			if (!result.paymentIntent || result.paymentIntent.status !== 'succeeded') {
				app_dialog.showSnackbar('Failed to process payment');
				return false;
			}
			setPaySuccess(true);
			app_dialog.showSnackbar('Payment succeeded');
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
			return true;
		} catch (e) {
			app_dialog.showError(e);
		} finally {
			setLoading(false);
		}
	}

	return {
		error,
		confirmPayment,
		loading,
		pay_success,
	}
}