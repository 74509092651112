import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { fromPromise } from 'apollo-link';
import * as qs from 'query-string';
import React from 'react';
import { useAppDialog } from '../app-dialog';
import { environment } from '../environment';
import firebase from '../firebase';
import { LoadingDots } from '../ui/loading-dots';
import { Signin } from './signin';
import { ProvideInvoice } from './use-invoice';

type Props = {
	children: React.ReactNode;
}

const fetchToken = async (invoice_id: string, invoice_secret: string) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var graphql = JSON.stringify({
		query: "query get_invoice_token($object: invoice_token_input!) {\n    get_invoice_token(object: $object) {\n        token\n    }\n}",
		variables: { "object": { invoice_id, invoice_secret } }
	})
	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: graphql,
	};

	const response = await fetch(environment.http_url, requestOptions);
	return await response.json();
}


export const ProvideApollo = ({ children }: Props) => {
	const app_dialog = useAppDialog();
	const [invoice_id, invoice_secret] = React.useMemo(() => {
		const path = window.location.pathname;
		const params = qs.parse(window.location.search || '');
		return [path.substring(path.lastIndexOf('/') + 1), params.secret as string];
	}, []) 
	
	const [is_ready, setIsReady] = React.useState(false);
	const [id_token, setIdToken] = React.useState<string>();
	const [user_id, setUserId] = React.useState<string>();

	React.useEffect(() => {
		if (!!user_id || !invoice_id || !invoice_secret) {
			return;
		}
		console.log('here');
		fetchToken(invoice_id, invoice_secret)
			.then((data: any) => {
				const token = data.data?.get_invoice_token?.token;
				if (!token) {
					console.error('Did not get a token')
					return;
				}
				firebase.auth().signInWithCustomToken(token);
			})
			.catch(e => app_dialog.showError(e))
	}, [invoice_id, invoice_secret])

	React.useEffect(() => {
		const sub = firebase.auth().onAuthStateChanged(async (firebase_user) => {
			setIsReady(true);
			if (!firebase_user) {
				setIdToken(undefined);
				setUserId(undefined);
				return;
			}
			const result = await firebase_user.getIdTokenResult();
			setUserId(firebase_user.uid);
			if (result.claims['https://hasura.io/jwt/claims']) {
				if (result.claims['https://hasura.io/jwt/claims']['x-hasura-invoice-id'] !== invoice_id) {
					firebase.auth().signOut();
					return;
				}
				setIdToken(result.token);
				return;
			}
			console.log('need to refresh claims');
			const endpoint = 'https://us-central1-edropin-amalgam.cloudfunctions.net/refreshToken';
			const refresh_result = await fetch(`${endpoint}?uid=${firebase_user.uid}`);
			if (refresh_result.status !== 200) {
				console.error(await refresh_result.json());
				return;
			}
			const refreshed_token = await firebase_user.getIdToken(true);
			setIdToken(refreshed_token);
		});
		return () => sub();
	}, []);

	const onRefresh = async (persist?: boolean) => {
		const id_token = await firebase.auth().currentUser?.getIdToken(true);
		if (persist) {
			setIdToken(id_token);
		}
		return id_token;
	}

	const client = React.useMemo(() => {
		return initApolloClient({ onRefresh });
	}, [id_token]);


	if (!is_ready || !client) {
		return <LoadingDots />
	}

	if (!user_id) {
		return <Signin />
	}

	return <ApolloProvider client={client}>
		<ProvideInvoice invoice_id={parseInt(invoice_id, 10)}>
			{children}
		</ProvideInvoice>
	</ApolloProvider>
}

const initApolloClient = ({
	onRefresh,
}: {
	onRefresh: (persist?: boolean) => Promise<string | undefined>;
}) => {
	let isRefreshing = false;
	let pendingRequests: any = [];
	const resolvePendingRequests = () => {
		pendingRequests.map((callback: any) => callback());
		pendingRequests = [];
	};
	const errorLink = onError(
		({ networkError, graphQLErrors, operation, forward }) => {
			if (networkError) {
				const err = networkError as any;
				const code = err && err.extensions && err.extensions.code;
				if (code === "start-failed") {
					console.error("Network: websocket start failed:", err.message);
				}
				console.error('Network error:', err);
				return;
			}
			if (graphQLErrors && graphQLErrors
				.findIndex(error => error.message === 'Could not verify JWT: JWTExpired') > -1) {
				let forward$;
				if (!isRefreshing) {
					isRefreshing = true;
					console.info('Refreshing token');
					forward$ = fromPromise(
						onRefresh(true)
							.then(() => {
								return true;
							})
							.then(() => {
								resolvePendingRequests();
								return true;
							})
							.catch(() => {
								pendingRequests = [];
								return false;
							})
							.finally(() => {
								isRefreshing = false;
							})
					);
				} else {
					forward$ = fromPromise(
						new Promise(resolve => {
							pendingRequests.push(() => resolve(0));
						})
					);
				}
				return forward$.flatMap(() => {
					return forward(operation);
				}) as any;
			}
		}
	);

	const authLink = setContext(async (operation, { headers }) => {
		const current_user = firebase.auth().currentUser;
		if (!current_user) {
			return {
				headers: {},
			}
		}
		return current_user.getIdToken()
			.then((token) => {
				return {
					headers: {
						...headers,
						authorization: token ? `Bearer ${token}` : ''
					}
				}
			})
	});

	const httpLink = new HttpLink({
		uri: environment.http_url,
	});

	const wsLink = new WebSocketLink({
		uri: environment.ws_url,
		options: {
			reconnect: true,
			lazy: true,
			connectionParams: async () => {
				const id_token = await onRefresh(false);
				if (!id_token) {
					return;
				}
				return {
					headers: {
						Authorization: `Bearer ${id_token}`,
					},
				}
			},
		}
	});

	const link = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		wsLink,
		from([authLink, httpLink]),
	);

	return new ApolloClient({
		link: from([errorLink, link]),
		connectToDevTools: !environment.production,
		cache: new InMemoryCache(),
	});
}
