import { TextField } from '@material-ui/core';
import React from "react";
import { textWithLineBreaks } from '../../utils/text-with-line-breaks';

export const StringWidget = (props: any) => {
	const { id, label, onChange, value, placeholder, required, rawErrors } = props;
	return (
		<TextField
			id={id}
			value={value || ''}
			placeholder={placeholder}
			onChange={ev => onChange(ev.target.value || undefined)}
			label={textWithLineBreaks(label)}
			required={required}
			error={!!rawErrors && rawErrors.length > 0}
		/>
	);
};