import { TextField } from '@material-ui/core';
import React from "react";

export const EmailWidget = (props: any) => {
	const { id, label, onChange, value, placeholder, required, rawErrors} = props;
	return (
		<TextField
			id={id}
			value={value || ''}
			placeholder={placeholder}
			type='email'
			onChange={ev => onChange(ev.target.value || undefined)}
			label={label}
			required={required}
			error={!!rawErrors && rawErrors.length > 0}
			/>
	);
};