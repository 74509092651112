import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";

export const DatePickerWidget = (props: any) => {
	const { id, label, onChange, value, placeholder, required } = props;
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<DatePicker
				id={id}
				value={value || null}
				placeholder={placeholder}
				format="MMM D, YYYY"
				onChange={date => onChange(date?.toISOString())}
				label={label}
				required={required}
			/>
		</MuiPickersUtilsProvider>
	);
};