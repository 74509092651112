import { createMuiTheme, ThemeOptions } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

export const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#005d8b',
      },
      secondary: {
        main: '#cb0e37',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#FFFFFF',
        paper: '#F0F0F0',
      },
      type: 'light',
    },
    typography: {
      fontSize: 14,
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTextField: {
        variant: 'outlined',
      },
      MuiFormControl: {
        variant: 'outlined',
      },
    },
  } as ThemeOptions);
  