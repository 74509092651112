import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { ArrayFieldTemplateProps } from "@rjsf/core";
import Form from "@rjsf/material-ui";
import React from 'react';
import { DatePickerWidget } from './date-picker-widget';
import './dynamic-form.css';
import { EmailWidget } from "./email-widget";
import { PasswordWidget } from "./password-widget";
import { SelectWidget } from "./select-widget";
import { StringWidget } from "./string-widget";


const ArrayFieldTemplate = (props: any) => {
	const classes = useArrayFieldStyles({});
	return (
		<div>
			{props.items.map((element: { children: any; }, idx: number) => <div
				key={idx}
				className={classes.arrayItem}>
				<Typography variant='overline'>Item {idx + 1}.</Typography>
				{element.children}
			</div>)}
			{props.canAdd && <Button type="button" onClick={props.onAddClick}></Button>}
		</div>
	);
}

const useArrayFieldStyles = makeStyles(theme => createStyles({
	arrayItem: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
		'&:last-child': {
			borderBottom: 0,
		}
	}
}));

function MyErrorListTemplate(props: any) {
	const { errors } = props;
	return (
		<Alert severity="error">There is some missing information in this form. Please verify all fields marked in red.</Alert>
	);
}

const widgets = {
	datepicker: DatePickerWidget,
	EmailWidget: EmailWidget,
	PasswordWidget: PasswordWidget,
	TextWidget: StringWidget,
	SelectWidget: SelectWidget,
	membership: StringWidget,
}
const ThemeObject = {
	ArrayFieldTemplate: ArrayFieldTemplate,
	ErrorList: MyErrorListTemplate,
	widgets,
};

type Props = {
	id: string;
	schema: any;
	ui_schema: any;
	form_data?: any;
	array_field_template?: React.StatelessComponent<ArrayFieldTemplateProps>;
	onChange: (data: any) => void;
	onSubmit?: (data: any) => void;
	children?: React.ReactNode;
	button_ref?: React.RefObject<HTMLButtonElement>;
	live_validate?: boolean;
	onError?: (e: any) => void;
}

export const DynamicForm = ({
	id,
	schema,
	ui_schema,
	form_data,
	array_field_template,
	onChange,
	onSubmit,
	children,
	live_validate,
	button_ref,
	onError,
}: Props) => {


	return <Form
		{...ThemeObject}
		ArrayFieldTemplate={array_field_template}
		id={id}
		schema={schema}
		uiSchema={ui_schema}
		className='dynamic-form'
		formData={form_data}
		onChange={onChange}
		onError={onError}
		widgets={widgets}
		liveValidate={live_validate}
		onSubmit={(e) => !!onSubmit ? onSubmit(e) : null}
		noHtml5Validate={true}
	>
		{children || <div></div>}
		<button ref={button_ref}
			type='submit' hidden={true}>Submit</button>
	</Form>
};