import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { ProvideApollo } from './apollo';
import { ProvideAppDialog } from './app-dialog/use-app-dialog';
import InvoicePage from './pages/invoice';
import { theme } from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAppDialog>
        <ProvideApollo>
          <InvoicePage/>
        </ProvideApollo>
      </ProvideAppDialog>
    </ThemeProvider>
  );
}

export default App;
