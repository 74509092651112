import { gql, useQuery } from "@apollo/client";
import { Box, Button, createStyles, lighten, makeStyles, Typography } from "@material-ui/core";
import BackIcon from '@material-ui/icons/ArrowBack';
import { Alert } from "@material-ui/lab";
import React from "react";
import { parseServerError } from "../../app-dialog/parse-server-error";
import { environment } from "../../environment";
import { InvoiceModel } from "../../models/invoice.model";
import { StripePaymentIntentStatus } from "../../models/stripe-payment-intent-status.enum";
import { EdContainer } from "../../ui/ed-container";
import { LoadingDots } from "../../ui/loading-dots";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { PayFormComponent } from "./pay-form.component";
import { usePay } from "./use-pay";

const QueryClientSecret = gql`
    query client_secret {
        get_invoice_client_secret {
            client_secret
        }
    }
`;

type Props = {
    invoice: InvoiceModel;
}

export const Pay = ({
    invoice,
}: Props) => {
    const classes = useStyles({});
    const {
        data,
        loading,
        error,
    } = useQuery(QueryClientSecret);
    const {
        pay_success,
        loading: paying,
        confirmPayment,
        error: card_error,
    } = usePay();

    const client_secret = data?.get_invoice_client_secret?.client_secret;

    if (loading) {
        return <LoadingDots />
    }

    const onSubmit = () => {
        confirmPayment(client_secret);
    }

    return <EdContainer max_width='sm'>
        {!client_secret ? <Box textAlign='center'>
            <Typography variant='h6'>
                This invoice is not payable</Typography>
            {error ? <Typography gutterBottom variant='body1'>
                {parseServerError(error)?.message || ''}
            </Typography> : null}
            <Button href={`${environment.frontend_url}/${invoice.organization.username}`}
                startIcon={<BackIcon />}
                color='primary'
                variant='outlined'>
                Back to {invoice.organization.name} events
        </Button>
        </Box> :
            <div className={classes.checkout}>
                <div className={classes.header}>
                    <Typography variant='h6'>Total to pay</Typography>
                    <Typography variant='h4'>{
                        getFormattedCurrency(invoice.amount, invoice.currency)}</Typography>
                </div>
                {pay_success && invoice.status !== StripePaymentIntentStatus.Succeeded ?
                    <Alert severity='info'>
                        Processing payment....
                    </Alert> : <div className={classes.content}>
                        <PayFormComponent
                            onSubmit={onSubmit}
                            loading={paying || (pay_success && invoice.status !== StripePaymentIntentStatus.Succeeded)}
                            card_error={card_error}
                            amount={invoice.amount}
                            currency={invoice.currency}
                        />
                    </div>}
                <div className={classes.footer}>
                    <Typography
                        variant='body2'
                        color='textSecondary'>{invoice.organization.name} partners with CertifySimple & Stripe to provide invoicing and payment processing. Have a question or concern about your invoice? Contact us at help@edropin.com</Typography>
                </div>
            </div>
        }
    </EdContainer >
}

const useStyles = makeStyles(theme => createStyles({
    checkout: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        margin: theme.spacing(4, 0),
        overflow: 'hidden',
    },
    header: {
        textAlign: 'center',
        padding: theme.spacing(2),
        color: theme.palette.primary.main,
        background: lighten(theme.palette.primary.main, .9),
        '& h4': {
            fontWeight: 'bold',
        }
    },
    content: {
        padding: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(2, 0),
    },
    cardNumber: {
        width: '100%',
        padding: theme.spacing(2),
    }
}))