export type InvoiceRefundModel = {
    id: number;
    created_at: string;
    invoice_id: number;
    amount: number;
}

export const InvoiceRefundFragment = `
    id
    created_at
    invoice_id
    amount
`;
