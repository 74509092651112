import { Box, Button, Typography } from "@material-ui/core";
import BackIcon from '@material-ui/icons/ArrowBack';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useInvoice } from "../../apollo/use-invoice";
import { environment } from "../../environment";
import { StripePaymentIntentStatus } from "../../models/stripe-payment-intent-status.enum";
import InvoiceReceipt from "../invoice-receipt";
import { Checkout } from "./checkout";
import { Pay } from "./pay";

export const Invoice = () => {
    const { invoice } = useInvoice();

    if (invoice.status === StripePaymentIntentStatus.Succeeded) {
        return <InvoiceReceipt invoice={invoice} />
    }

    const stripePromise = loadStripe(environment.stripe_key, {
        stripeAccount: invoice.organization.stripe_id,
    });


    if (invoice.cancelled_at) {
        return <Box textAlign='center'>
            <Typography variant='h6'>
                This invoice has been cancelled</Typography>
            <Button href={`${environment.frontend_url}/${invoice.organization.username}`}
                startIcon={<BackIcon />}
                color='primary'
                variant='outlined'>
                Back to {invoice.organization.name} events
        </Button>
        </Box>
    }

    if (!invoice.status) {
        return <Checkout invoice={invoice} />
    }

    return <Elements stripe={stripePromise}>
        <Pay invoice={invoice} />
    </Elements>
}
