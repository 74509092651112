export type AttendeeModel = {
    id: number;
    created_at: string;
    updated_at: string;
    agd_id?: string;
    email: string;
    name: string;
    data?: any;
};


export const AttendeeFragment = `
id
created_at
updated_at
name
email
agd_id
data
`;
