import { createStyles, makeStyles, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { environment } from '../../environment';
import { Currency } from '../../models/currency';
import { LoadingButton } from '../../ui/loading-button';
import { SupportedCardsComponent } from '../../ui/supported-cards';
import { getFormattedCurrency } from '../../utils/get-formatted-currency';
import { StyledCardElement } from './styled-card-element';
import { TestCardsComponent } from './test-cards.component';

interface Props {
	loading?: boolean;
	amount: number;
	currency: Currency;
	card_error?: string;
	onSubmit: () => void;
}

export const PayFormComponent = ({
	loading,
	amount,
	currency,
	card_error,
	onSubmit,
}: Props) => {
	const classes = useStyles({});

	return <div className={classes.root}>
		<Typography variant='overline'>Billing Information</Typography>
		<StyledCardElement card_error={card_error} />
		<LoadingButton
			variant='contained'
			color='secondary'
			fullWidth
			size='large'
			onClick={onSubmit}
			className={classes.action}
			startIcon={<LockIcon />}
			loading={loading}
		>Pay now {getFormattedCurrency(
			amount, currency
		)}</LoadingButton>
		<SupportedCardsComponent size='small' />
		{environment.production ? null : <TestCardsComponent />}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
	},
	action: {
		marginTop: theme.spacing(2),
	}
}));