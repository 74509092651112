import { createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { EdContainer } from "../ui/ed-container";
import { LoadingDots } from "../ui/loading-dots";


type Props = {

}

export const Signin = (props: Props) => {
    const classes = useStyles({});
    return <EdContainer max_width='sm'>
        <Typography
            align='center' gutterBottom variant='h4'>One moment...</Typography>
        <Typography
            align='center' gutterBottom variant='h6'>We're just setting up your invoice session.</Typography>
        <LoadingDots />
    </EdContainer>
}

const useStyles = makeStyles(theme => createStyles({

}))