import React from 'react';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAppDialog } from '../../app-dialog';

type Props = {
}

const Cards = [
	{
		number: '4242 4242 4242 4242',
		brand: 'VISA',
		cvc: 'Any 3 digits',
	},
	{
		number: '4000 0012 4000 0000',
		brand: 'VISA (Canada)',
		cvc: 'Any 3 digits',
	},
	{
		number: '5555 5555 5555 4444',
		brand: 'Mastercard',
		cvc: 'Any 3 digits',
	},
	{
		number: '3782 822463 10005',
		brand: 'AMEX',
		cvc: 'Any 4 digits',
	},
	{
		number: '6011 1111 1111 1117',
		brand: 'Discover',
		cvc: 'Any 3 digits',
	},
	{
		number: '4000 0025 0000 3155',
		brand: 'VISA (3D Secure)',
		cvc: 'Any 3 digits',
	},
	{
		number: '4000 0000 0000 0002',
		brand: 'CARD WILL DECLINE',
		cvc: 'Any 3 digits',
	}
];

export const TestCardsComponent = ({ }: Props) => {
	const app_dialog = useAppDialog();
	const classes = useStyles({});

	const onCopy = () => {
		app_dialog.showSnackbar('Card copied. Please paste it in the card input.')
	}
	return <div className={classes.testCards}>
		<Typography variant='body2' color='secondary'><u>Checkout is in test mode</u>.
		Real credit cards will not work. Click one of the following credit
		card numbers to copy it and paste it in the card input.
		You can use <strong>any future expiry date</strong> for these cards</Typography>
		<table className={classes.cardTable}>
			<thead>
				<tr>
					<td>
						Number
				</td>
					<td>
						Brand
				</td>
					<td>
						CVC
				</td>
				</tr>
			</thead>
			<tbody>
				{Cards.map(card =>
					<CopyToClipboard onCopy={onCopy} text={card.number}
						key={card.number}>
						<tr>
							<td>
								{card.number}
							</td>
							<td>
								{card.brand}
							</td>
							<td>
								{card.cvc}
							</td>
						</tr>
					</CopyToClipboard>)}
			</tbody>
		</table>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	testCards: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	cardTable: {
		width: '100%',
		display: 'table',
		'& tr': {
			cursor: 'pointer',
		},
		'& tr:nth-child(even) td': {
			background: theme.palette.grey['300'],
		},
		'& tr:hover td': {
			background: theme.palette.grey['200'],
			color: theme.palette.primary.main,
		},
		'& td': {
			padding: theme.spacing(.5),
		}
	},
}));