import React from 'react';
import { EdContainer } from '../ui/ed-container';
import { AppFooter } from './app-footer';
import { AppHeader } from './app-header';

type Props = {
	no_container?: boolean;
	max_width?: 'sm' | 'md' | 'lg' | 'xl';
}

export const AppLayout: React.FC<Props> = ({
	no_container,
	max_width = 'md',
	children
}) => {

	return <>
		<AppHeader />
		{no_container ? <>
			{children}
		</> : <EdContainer max_width={max_width}>
				<>
					{children}
				</>
			</EdContainer>}
		<AppFooter />
	</>
}
