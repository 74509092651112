import { Box, Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { InvoiceModel } from "../../models/invoice.model";
import { DynamicForm } from "../../ui/dynamic-form";
import { getFormattedCurrency } from "../../utils/get-formatted-currency";
import { ArrayFieldTemplateProps } from '@rjsf/core';
import { PageHeader } from "../../ui/page-header";
import { gql, useMutation } from "@apollo/client";
import { LoadingButton } from "../../ui/loading-button";
import ChevronRight from "@material-ui/icons/ChevronRight";

type Props = {
    invoice: InvoiceModel
}



const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    const classes = useArrayFieldStyles({});
    const { title, schema } = props;
    const additionalProperties = (schema?.additionalProperties || {}) as any;
    return (
        <div className={classes.item}>
            <div className={classes.header}>
                <div className='left'>
                    <Typography variant='h6'>{title}</Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'>{getFormattedCurrency(
                            additionalProperties.price,
                            additionalProperties.currency)} x {additionalProperties.quantity}</Typography>
                </div>
                <div className='right'>
                    {getFormattedCurrency(additionalProperties.quantity * additionalProperties.price,
                        additionalProperties.currency)}
                </div>
            </div>
            {
                props.items.map((element: { children: any; }, idx: number) => <div
                    key={idx}
                    className={classes.arrayItem}>
                    <Typography variant='overline'>Item {idx + 1}.</Typography>
                    {element.children}
                </div>)
            }
        </div >
    );
}

const useArrayFieldStyles = makeStyles(theme => createStyles({
    item: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        }
    },
    arrayItem: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        '&:last-child': {
            borderBottom: 0,
        }
    },
    header: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        '& .left': {
            flex: 1,
        },
        '& .right': {
            textAlign: 'right',
        }
    }
}));

const Mutation = gql`
    mutation($object: checkout_input!) {
        finish_checkout(object: $object) {
            success
        }
    }
`;

export const Checkout = ({
    invoice,
}: Props) => {
    const app_dialog = useAppDialog();
    const classes = useStyles({});
    const [form_data, setFormData] = React.useState<any>();
    const submit_ref = React.useRef<HTMLButtonElement>(null);
    const [
        finishCheckout,
        finish_checkout_status,
    ] = useMutation(Mutation);


    const onChange = ({ formData }: any) => {
        setFormData(formData);
    }

    const onFormError = () => {
        app_dialog.showSnackbar('Please fix all fields marked in red', { variant: 'error' })
    }


    const onSubmit = async ({ formData }: any) => {
        try {
            setFormData(formData);
            const { data } = await finishCheckout({
                variables: {
                    object: {
                        data: formData,
                    },
                }
            })
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const [schema, ui_schema] = React.useMemo(() => {
        if (!invoice.invoice_items) {
            return [undefined, undefined];
        }
        return [
            {
                type: 'object',
                properties: invoice.invoice_items
                    .filter(i => !!i.schema)
                    .reduce((obj, item) => ({
                        ...obj,
                        [`item-${item.ticket.id}`]: {
                            ...item.schema.schema,
                            title: `${item.ticket.event.title}: ${item.ticket.name}`,
                            additionalProperties: {
                                quantity: item.quantity,
                                price: item.price,
                                currency: invoice.currency,
                            },
                        },
                    }), {}),
            },
            invoice.invoice_items
                .filter(i => !!i.schema)
                .reduce((obj, item) => ({
                    ...obj,
                    [`item-${item.ticket.id}`]: item.schema.ui_schema,
                }), {}),
        ];
    }, [invoice.invoice_items])

    React.useEffect(() => {
        const form_data = invoice.invoice_items
            .filter(i => !!i.schema)
            .reduce((obj, item) => ({
                ...obj,
                [`item-${item.ticket.id}`]: item.data || Array(item.quantity).fill('').map(i => ({})),
            }), {});
        setFormData(form_data);
    }, [invoice.invoice_items])

    return <Box pt={4} pb={4}>
        <PageHeader
            title='Checkout'
        />
        <div className={classes.info}>
            <div style={{ flex: 1 }}>
                <Typography variant='overline'>Invoice Info</Typography>
                <Typography variant='h6'>
                    {invoice.name}
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                    {invoice.email}
                </Typography>
                {invoice.agd_id ? <Typography variant='body2'>AGD Discount Applied: #{invoice.agd_id}</Typography> : null}
                {invoice.discount_code ? <Typography variant='body2'>Discount Code Applied: #{invoice.discount_code}</Typography> : null}
            </div>
            <Typography variant='h4'>
                {getFormattedCurrency(invoice.amount, invoice.currency)}
            </Typography>
        </div>
        {!!form_data ? <DynamicForm
            id='checkout-form'
            schema={schema}
            ui_schema={ui_schema}
            onSubmit={onSubmit}
            onChange={onChange}
            array_field_template={ArrayFieldTemplate}
            onError={onFormError}
            live_validate={true}
            form_data={form_data}
            button_ref={submit_ref} /> : null}
        <LoadingButton fullWidth
            variant='contained'
            size='large'
            loading={finish_checkout_status.loading}
            onClick={() => submit_ref.current?.click()}
            color='secondary'
            endIcon={<ChevronRight />}
        >Proceed to Payment</LoadingButton>
    </Box>
}

const useStyles = makeStyles(theme => createStyles({
    info: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '& h4': {
            fontWeight: 'bold',
        }
    }
}))